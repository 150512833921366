<template>
    <div>
        <b-container>
            <b-row>
                <b-col>
                    <div class="col-login mx-auto">
                        <b-card>
                            <div class="text-center">
                                <img src="@/assets/logos/logo-extenso-escuro.svg"  alt="">
                            </div>
                            <br>
                            <div>
                                <div class="card-title text-center">
                                    <p>Olá! Vamos começar</p>
                                    <small>Informe os campos abaixo para entrar.</small>
                                </div>
                                <form>

                                    <b-form-group>
                                            <label class="form-label">E-mail</label>
                                            <input type="email" class="form-control" v-model="email" placeholder="Digite seu e-mail" >
                                    </b-form-group>
                                    <b-form-group>
                                            <label class="form-label" for="feedback-user">
                                                Senha
                                                <router-link to="/Forgot" class="float-right">Esqueci a senha</router-link>
                                            </label>
                                            <InputPassword v-model="password"/>
                                    </b-form-group>
                                    <b-form-group>
                                        <b-button block variant="primary" @click="Auth">Entrar</b-button>
                                    </b-form-group>                         
                                </form>
                                <div class="text-center text-muted mt-3">
                                    Ainda não tem conta?   <router-link to="/SignUp" >Cadastre-se</router-link> 
                                </div>
                                <hr>
                                <b-form-group class="text-center">
                                    <b-row>
                                        <b-col>
                                            <b-button block variant="danger" @click="AuthGoogle">
                                                <font-awesome-icon :icon="['fab', 'google']" /> Google
                                            </b-button>
                                        </b-col>
                                        <!-- <b-col>
                                            <b-button block variant="info" @click="AuthFacebook">
                                                <font-awesome-icon :icon="['fab', 'facebook-square']" /> Facebook
                                            </b-button>
                                        </b-col> -->
                                    </b-row>
                                </b-form-group>
                            </div>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import firebase from "firebase/app"
import { mapActions } from 'vuex'
import InputPassword from '@/components/widgets/InputPassword'
export default {
    components:{  InputPassword },
    data() {
        return {
            email:"",
            password:"",
        }
    },
    methods:{
        ...mapActions('global', ['TranslateErrorMessageFirebase']),
        Auth(){
            firebase.auth().signInWithEmailAndPassword(this.email, this.password)
                .then(() => {
                    firebase.analytics().logEvent('Access Platform');
                    this.$router.push('home')
                })
                .catch(async (error) => {
                    if(error.code == 'auth/user-not-found'){
                        this.VerifyOld()
                        return;
                    }
                    let response = await this.TranslateErrorMessageFirebase(error)
                    this.$swal(response.Title, response.ErrorMessage, response.Type)
                })
        },
        AuthGoogle(){
            const provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth().signInWithPopup(provider)
                .then(() => {
                    firebase.analytics().logEvent('Access Platform by Google');
                    this.$router.push('home')
                }).catch(async (error) => {
                    let response = await this.TranslateErrorMessageFirebase(error)
                    this.$swal(response.Title, response.ErrorMessage, response.Type)
                });
        },
        AuthFacebook(){
            const provider = new firebase.auth.FacebookAuthProvider();
            firebase.auth().signInWithPopup(provider)
                .then(() => {
                    firebase.analytics().logEvent('Access Platform by Facebook');
                    this.$router.push('home')
                }).catch(async (error) => {
                    let response = await this.TranslateErrorMessageFirebase(error)
                    this.$swal(response.Title, response.ErrorMessage, response.Type)
                });
        },
        VerifyOld(){
            let params = new URLSearchParams()
            params.append('email', this.email)
            params.append('password', this.password)

            this.$api.post('/Login/GetUser', params)
                .then(async response => {
                    if(!response.data.error){
                        this.CreateAccont()
                    } else {
                        let response = await this.TranslateErrorMessageFirebase( {code :'auth/user-not-found' })
                        this.$swal(response.Title, response.ErrorMessage, response.Type)
                    }
                })
            
        },
        CreateAccont(){
            firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
                .then(() => {
                    firebase.analytics().logEvent('Access Platform by Postgresql ');
                    this.$router.push('home')
                })
                .catch(async (error) => {
                    let response = await this.TranslateErrorMessageFirebase(error)
                    this.$swal(response.Title, response.ErrorMessage, response.Type)
                });
        },
        
    }

}
</script>

<style>

</style>