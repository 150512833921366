<template>
    <div>
        <b-container>
            <b-row>
                <b-col>
                    <div class="col-login mx-auto">
                        <b-card>
                            <div class="text-center">
                                <img src="@/assets/logos/logo-extenso-escuro.svg"  alt="">
                            </div>
                            <br>
                            <div class="text-center card-title">Esqueceu sua senha?</div>
                                <div class="form-group">
                                    <label class="form-label" >E-mail</label>
                                    <input type="email" class="form-control" v-model="email" placeholder="Digite seu e-mail">
                                </div>
                                <div class="form-footer">
                                    <b-button block variant="primary" @click="PasswordReset">Recuperar Senha</b-button>
                                </div>
                                <div class="text-center text-muted mt-3 ">
                                Esqueça, <router-link to="/" >envie-me de volta</router-link> para a tela de login.
                            </div>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import firebase from "firebase/app"
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            email:"",
        }
    },
    methods:{
        ...mapActions('global', ['TranslateErrorMessageFirebase']),
        PasswordReset(){
            firebase.auth().sendPasswordResetEmail(this.email)
                .then(() => {
                    this.$swal("Recuperação de senha","Foi enviado para seu e-mail um link onde é possível trocar sua senha.", "success")
                })
                .catch(async (error) => {
                    let response = await this.TranslateErrorMessageFirebase(error)
                    this.$swal(response.Title, response.ErrorMessage, response.Type)
                });
        },
    }
}
</script>

<style>

</style>