<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <div class="col-login mx-auto">
            <b-card>
              <div class="text-center">
                <img src="@/assets/logos/logo-extenso-escuro.svg" alt="" />
              </div>
              <br />
              <div class="card-title text-center">Criar nova conta</div>

              <div class="form-group">
                <label class="form-label">E-mail</label>
                <input
                  type="email"
                  :class="'form-control ' + GetError('email')"
                  v-model="email.input"
                  placeholder="Digite seu e-mail"
                />
                <div class="invalid-feedback">{{ email.errorMessage }}</div>
              </div>
              <b-form-group>
                <label class="form-label">Senha</label>
                <InputPassword
                  v-model="password.input"
                  :CompClass="GetError('password')"
                  :errorMessage="password.errorMessage"
                />
              </b-form-group>
              <b-form-group>
                <label class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    v-model="accept"
                  />
                  <span class="custom-control-label pl-5">
                    Eu li e concordo com
                    <a v-if="!$isMobile()" href="#" @click="ShowTerms"
                      >termos e política</a
                    >
                    <a
                      v-if="$isMobile()"
                      @click="viewTerms = true"
                      href="https://carteiraholder.com.br/docs/Condicoes-e-Termos-de-Uso-Carteira-Holder.pdf"
                      target="_blank"
                      >termos e política</a
                    >
                  </span>
                </label>
              </b-form-group>
              <b-form-group>
                <b-button block variant="primary" @click="CreateAccont"
                  >Criar nova conta</b-button
                >
              </b-form-group>
              <div class="text-center text-muted mt-4">
                Já tem conta? <router-link to="/">Acessar</router-link>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="terms"
      title="Termos e Política"
      @ok="accept = true"
      @cancel="accept = false"
      ok-title="Aceitar"
      ok-variant="cyan"
      cancel-title="Cancelar"
      cancel-variant="red"
      size="xl"
    >
      <iframe
        src="https://carteiraholder.com.br/docs/Condicoes-e-Termos-de-Uso-Carteira-Holder.pdf"
        frameBorder="0"
        scrolling="auto"
        height="600px"
        width="100%"
      ></iframe>
    </b-modal>
  </div>
</template>

<script>
import InputPassword from "@/components/widgets/InputPassword";
import firebase from "firebase/app";
import { mapActions } from "vuex";
export default {
  components: { InputPassword },
  data() {
    return {
      email: {
        input: "",
        error: false,
        errorMessage: "",
      },
      password: {
        input: "",
        error: false,
        errorMessage: "",
      },
      accept: false,
      viewTerms: false,
    };
  },
  watch: {
    accept(newVal, oldVal) {
      // console.log(newVal, this.accept);
      if (!this.viewTerms && newVal) {
        this.$swal(
          "Atenção",
          "É necessário ler os termos antes de aceitá-los",
          "warning"
        );
        setTimeout(() => {
          this.accept = oldVal;
        }, 100);
      }
    },
  },
  methods: {
    ...mapActions("global", [
      "TranslateErrorMessageFirebase",
      "GetAccessToken",
    ]),
    ShowTerms() {
      this.viewTerms = true;
      this.$bvModal.show("terms");
    },
    GetError(field) {
      if (this[field].error) return "is-invalid state-invalid";
      return "";
    },
    ClearError(field) {
      this[field].error = false;
      this[field].errorMessage = "";
    },
    CheckPassword() {
      if (this.password.input.length < 8) {
        this.password.errorMessage =
          "Sua senha deve ter pelo menos oito caracteres.";
        this.password.error = true;
      } else {
        this.ClearError("password");
      }
    },
    CheckEmail() {
      if (!this.ValidateEmail(this.email.input)) {
        this.email.errorMessage = "Informe um e-mail valido.";
        this.email.error = true;
      } else {
        this.ClearError("email");
      }
    },
    ValidateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    CreateAccont() {
      this.CheckPassword();
      this.CheckEmail();
      if (this.email.error || this.password.error) return;
      if (!this.accept) {
        this.$swal(
          "Atenção",
          "Para criar o cadastro é preciso aceitar os termos",
          "warning"
        );
        return;
      }
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email.input, this.password.input)
        .then(() => {
          this.CreatedPortfolio();
          this.$swal(
            "Seja bem-vindo",
            "Sua conta foi cadastrada com sucesso!",
            "success"
          ).then(() => this.$router.replace("home"));
        })
        .catch(async (error) => {
          let response = await this.TranslateErrorMessageFirebase(error);
          this.$swal(response.Title, response.ErrorMessage, response.Type);
        });
    },
    async CreatedPortfolio() {
      this.$store.state.user.CurrentUser = firebase.auth().currentUser;

      const AccessToken = await this.GetAccessToken([
        this.$store.state.user.CurrentUser.email,
      ]);
      // console.log(AccessToken);
      let params = new URLSearchParams();
      params.append("email", this.$store.state.user.CurrentUser.email);
      params.append("AccessToken", AccessToken);

      this.$newApi
        .post("/Portfolio/GetNewPortfolio", params)
        .then((Portfolio) => {
          if (Portfolio.data.error) {
            this.GetSnapshot();
            return;
          }
          const JsonPortfolio = Portfolio.data;
          firebase
            .firestore()
            .collection("account")
            .doc(this.$store.state.user.CurrentUser.uid)
            .set(JsonPortfolio)
            .catch((error) => {
              let params = new URLSearchParams();
              params.append("email", this.$store.state.user.CurrentUser.email);
              params.append("event", JSON.stringify(error));
              this.$api.post("/Errorlog/Set", params);
              console.log(error);
            });
        });
    },
  },
};
</script>

<style></style>
