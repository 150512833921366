<template>
   <div>
        <b-container>
            <b-row>
                <b-col>
                    <div v-if="operation == 'PASSWORD_RESET'" class="col-login mx-auto">
                        <b-card>
                            <div class="text-center">
                                <img src="@/assets/logos/logo-extenso-escuro.svg"  alt="">
                            </div>
                            <br>
                            <div class="form-group">
                                <label class="form-label" >Senha </label>
                                <InputPassword v-model="password" />
                            </div>
                            <div class="form-footer">
                                <b-button block variant="primary" @click="PassordReset">Trocar senha</b-button>
                            </div>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import InputPassword from '@/components/widgets/InputPassword'
import firebase from "firebase/app"
import { mapActions } from 'vuex'
export default {
    components:{  InputPassword },
    data() {
        return {
            password:"",
            operation:""
        }
    },
	methods:{
         ...mapActions('global', ['TranslateErrorMessageFirebase']),
		PassordReset(){
            firebase.auth().checkActionCode(this.$route.query.oobCode)
                .then(() => {
                    firebase.auth().confirmPasswordReset(this.$route.query.oobCode, this.password)
                        .then(() => {
                            this.$swal("Sua senha foi alterado com sucesso!","Faça, seu login novamente e aproveite!", "success").then(() => this.$router.replace('/'))
                        }).catch(async (error) => {
                            let response = await this.TranslateErrorMessageFirebase(error)
                            this.$swal(response.Title, response.ErrorMessage, response.Type)
                        });
                }).catch(async (error) => {
                    let response = await this.TranslateErrorMessageFirebase(error)
                    this.$swal(response.Title, response.ErrorMessage, response.Type)
                });
		},
	},
    created(){
        firebase.auth().checkActionCode(this.$route.query.oobCode)
			.then((user) => {
                this.operation = user.operation
                if(user.operation != 'PASSWORD_RESET') this.$router.replace('/')
			}).catch(() => {
				this.$router.replace('/')
			});
    }
}
</script>

<style>

</style>