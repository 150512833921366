<template>
    <div class="input-group">
            <input
                :type="type" :class="'form-control ' + CompClass " autocomplete="off"
                :value="value"   @input="$emit('input', $event.target.value)" 
                :placeholder="placeholder == null ? 'Digite sua senha' : placeholder"
            />
        
        <span class="input-group-append">
        <button
            class="btn btn-primary"
            v-b-tooltip.hover  :title="tooltip"
            type="button"  @click="ShowHide"
        >
            <font-awesome-icon :icon="icon" />
        </button>
        </span>
        <div class="invalid-feedback">{{errorMessage}}</div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: String, required: true },
        CompClass: { type: String }, 
        errorMessage: { type: String },
        placeholder: { type: String },
    },
    data() {
        return {
            tooltip: "Mostrar senha",
            type: "password",
            icon: "eye",
        };
    },
    methods: {
        ShowHide() {
            if (this.type == "password") {
                this.tooltip = "Esconder senha";
                this.type = "text";
                this.icon = "eye-slash";
            } else {
                this.tooltip = "Mostrar senha";
                this.type = "password";
                this.icon = "eye";
            }
        },
    },
};
</script>

<style>
</style>